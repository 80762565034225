import axios from "axios";
const corsAny = "https://corsanywhere-vjuqlq6gsq-wl.a.run.app";
const urlOldApiEndpoint = `${corsAny}/${process.env.VUE_APP_API_PATH}/v1`; // OLD SC API PROXY URL
const urlScriptCaseDirect = `${corsAny}/${process.env.VUE_APP_SCRIPTCASE_API_PATH}`;

let apiBaseUrl, apiGatewayUrlNonLocal;
let loginBaseUrl;
if (process.env.VUE_APP_ENV == "local") {
  loginBaseUrl = `${corsAny}/${process.env.VUE_APP_API_PATH_GATEWAY}/v1`;

  apiBaseUrl = `${process.env.VUE_APP_API_PATH_GATEWAY_LOCAL}`;
  console.log(apiBaseUrl);
  console.log(process.env.VUE_APP_API_PATH_GATEWAY_LOCAL);

  apiGatewayUrlNonLocal = `${corsAny}/${process.env.VUE_APP_VENDOR_DIRECT_URL}`;
} else if (
  process.env.VUE_APP_ENV == "dev" ||
  process.env.VUE_APP_ENV == "development" ||
  process.env.VUE_APP_ENV == "uat"
) {
  loginBaseUrl = `${corsAny}/${process.env.VUE_APP_API_PATH_GATEWAY}/v1`;

  apiBaseUrl = `${corsAny}/${process.env.VUE_APP_VENDOR_DIRECT_URL}`;
  apiGatewayUrlNonLocal = `${corsAny}/${process.env.VUE_APP_VENDOR_DIRECT_URL}`;
} else {
  loginBaseUrl = `${corsAny}/${process.env.VUE_APP_API_PATH_GATEWAY}/v1`;

  apiBaseUrl = `${process.env.VUE_APP_VENDOR_DIRECT_URL}`;
  apiGatewayUrlNonLocal = `${process.env.VUE_APP_VENDOR_DIRECT_URL}`;
}

console.log("process.env.VUE_APP_ENV", process.env.VUE_APP_ENV);
console.log("apiBaseUrl", apiBaseUrl);
console.log("apiGatewayUrlNonLocal", apiGatewayUrlNonLocal);

/**
 * OLD
 * API methods going to Scriptcase
 *
 * !! These need to be added or updated in the new repo !!
 */

const create_header = (data) => {
  const token = localStorage.getItem("access_token");

  return axios({
    method: "post",
    url: `${urlScriptCaseDirect}/api_vendor_inventory_layout/`,
    data: { ...data },
    headers: { "Content-Type": "application/json" },
    params: {
      token,
    },
  })
    .then((res) => res.data)
    .catch((err) => err.response.data);
};

const get_header = () => {
  const token = localStorage.getItem("access_token");

  return axios({
    method: "get",
    url: `${urlScriptCaseDirect}/api_vendor_inventory_layout/`,
    params: {
      token,
    },
  })
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      return err.response;
    });
};

const update_header = (data) => {
  const token = localStorage.getItem("access_token");
  return axios({
    method: "put",
    url: `${urlOldApiEndpoint}/inventory/grid/layout`,
    data: { ...data },
    headers: { "Content-Type": "application/json" },
    params: {
      token,
    },
  })
    .then((res) => res.data)
    .catch((err) => err.response.data);
};

const delete_header = (data) => {
  const token = localStorage.getItem("access_token");

  return axios({
    method: "delete",
    url: `${urlOldApiEndpoint}/inventory/grid/layout`,
    data: { ...data },
    headers: { "Content-Type": "undefined" },
    params: {
      token,
    },
  })
    .then((res) => res.data)
    .catch((err) => err.response.data);
};

const create_buyer_party = (data) => {
  const token = localStorage.getItem("access_token");

  return axios({
    method: "post",
    url: `${urlOldApiEndpoint}/organizations`,
    data: { ...data },
    headers: { "Content-Type": "application/json" },
    params: {
      token,
    },
  })
    .then((res) => res.data)
    .catch((err) => err.response.data);
};

const update_buyer_party = (data) => {
  const token = localStorage.getItem("access_token");
  return axios({
    method: "put",
    url: `${urlOldApiEndpoint}/organizations`,
    data: { ...data },
    headers: { "Content-Type": "application/json" },
    params: {
      token,
      type: "data",
    },
  })
    .then((res) => res.data)
    .catch((err) => err.response.data);
};

const update_toggle_buyer_party = (data) => {
  console.log(data);
  const token = localStorage.getItem("access_token");
  return axios({
    method: "put",
    url: `${urlOldApiEndpoint}/organizations`,
    data: { ...data },
    headers: { "Content-Type": "application/json" },
    params: {
      token,
      type: "toggle",
    },
  })
    .then((res) => res.data)
    .catch((err) => err.response.data);
};

const get_saved_filters = () => {
  const token = localStorage.getItem("access_token");
  return axios({
    method: "get",
    url: `${apiBaseUrl}/inventory/filters`,
    headers: { authorization: token },
  })
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      return err.response;
    });
};

const create_filter = (data) => {
  const token = localStorage.getItem("access_token");
  return new Promise((resolve, reject) => {
    axios({
      method: "post",
      url: `${apiBaseUrl}/inventory/filters`,
      data,
      headers: { authorization: token },
    })
      .then((res) => {
        console.log("get_flags_instructions data", res);
        return resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

const update_saved_filter = (data) => {
  const token = localStorage.getItem("access_token");
  return new Promise((resolve, reject) => {
    axios({
      method: "put",
      url: `${apiBaseUrl}/inventory/filters`,
      data,
      headers: { authorization: token },
    })
      .then((res) => {
        console.log("get_flags_instructions data", res);
        return resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

const delete_filter = (data) => {
  const token = localStorage.getItem("access_token");
  return new Promise((resolve, reject) => {
    axios({
      method: "delete",
      url: `${apiBaseUrl}/inventory/filters`,
      data,
      headers: { authorization: token },
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

// const create_api_tb_vendor_inventory = (data) => {
//   const token = localStorage.getItem("access_token");
//   console.log(data);
//   return axios({
//     method: "post",
//     url: `${urlOldApiEndpoint}/inventory`,
//     data: { ...data },

//     params: {
//       token,
//       type: "inventory",
//     },
//   })
//     .then((res) => res.data)
//     .catch((err) => err.response.data);
// };

const create_api_tb_vendor_inventory = (data) => {
  const token = localStorage.getItem("access_token");
  console.log(data);
  return axios({
    method: "POST",
    url: `${apiBaseUrl}/inventory`,
    data: { ...data },
    headers: { authorization: token },
  })
    .then((res) => res.data)
    .catch((err) => err.response.data);
};

// const update_api_tb_vendor_inventory = (data) => {
//   const token = localStorage.getItem("access_token");
//   return axios({
//     method: "put",
//     url: `${urlOldApiEndpoint}/inventory`,
//     data: { ...data },
//     // headers: { Authorization: token },
//     headers: { "Content-Type": "undefined" },

//     params: {
//       token,
//       type: "inventory",
//     },
//   })
//     .then((res) => res.data)
//     .catch((err) => err.response.data);
// };

const update_api_tb_vendor_inventory = (data) => {
  const token = localStorage.getItem("access_token");
  return axios({
    method: "PUT",
    url: `${apiBaseUrl}/inventory/manual`,
    data: { ...data },
    headers: { authorization: token },
  })
    .then((res) => res.data)
    .catch((err) => err.response.data);
};

const create_vendor_inventory_update_log = (data) => {
  console.log("data ti api tb vedor inventory tmp", data);

  const token = localStorage.getItem("access_token");
  return axios({
    method: "post",
    url: `${urlOldApiEndpoint}/log/inventory`,
    headers: { "Content-Type": "application/json" },
    data: { ...data },
    params: {
      token,
    },
  })
    .then((res) => res.data)
    .catch((err) => err.response.data);
};

const upload_inventory_file_new_system = (
  data,
  mapname,
  keepExistingLastMod
) => {
  const token = localStorage.getItem("access_token");

  console.log("upload_inventory_file_new_system mapname", mapname);

  console.log("upload_inventory_file_new_system", data);

  let workAround =
    process.env.VUE_APP_ENV == "dev" ? "development" : process.env.VUE_APP_ENV;

  let url = `https://nereus-inf-api-vendor-inventory-${workAround}-vjuqlq6gsq-uw.a.run.app`;

  if (process.env.VUE_APP_ENV == "local") {
    url = `${apiBaseUrl}`;
    // url = `${apiBaseUrl}/vendor`;
  }

  return axios({
    method: "post",
    url: `${url}/import/file`,
    data: data,
    params: {
      mapname,
      keepExistingLastMod,
    },
    headers: { "Content-Type": "undefined", authorization: token },
  })
    .then((res) => res.data)
    .catch((err) => {
      if (!err) {
        return { status: 600 };
      }
      if (!err.response) {
        // network error
        return { status: 600 };
      } else {
        return err.response.data;
      }
    });
};

const upload_inventory_file = (data) => {
  const token = localStorage.getItem("access_token");
  console.log("upload_inventory_file", data);
  return axios({
    method: "post",
    url: `${urlScriptCaseDirect}/api-vendor-file-upload-ext/`,
    data: data,
    headers: { "Content-Type": "undefined" },
    params: {
      token,
    },
  })
    .then((res) => res.data)
    .catch((err) => {
      if (!err) {
        return { status: 600 };
      }
      if (!err.response) {
        // network error
        return { status: 600 };
      } else {
        return err.response.data;
      }
    });
};

const upload_inventory_file_step2 = (data) => {
  const token = localStorage.getItem("access_token");
  return axios({
    method: "post",
    url: `${urlScriptCaseDirect}/api_vendor_fileupload-ext/`,
    data: data,
    headers: { "Content-Type": "undefined" },
    params: {
      token,
    },
  })
    .then((res) => res.data)
    .catch((err) => {
      if (!err) {
        return { status: 600 };
      }
      if (!err.response) {
        // network error
        return { status: 600 };
      } else {
        return err.response.data;
      }
    });
};

const validate_inventory_file = (data) => {
  const token = localStorage.getItem("access_token");

  return axios({
    method: "GET",
    url: `${urlScriptCaseDirect}/api_message_validation-ext/`,
    params: {
      token,
      file_id: data.file_id,
    },
  })
    .then((res) => res.data)
    .catch((err) => {
      if (!err) {
        return { status: 600 };
      }
      if (!err.response) {
        // network error
        return { status: 600 };
      } else {
        return err.response.data;
      }
    });
};

const convert_inventory_file = (data) => {
  const token = localStorage.getItem("access_token");
  return axios({
    method: "post",
    url: `${urlScriptCaseDirect}/api_message_update_lat/`,
    data: { ...data },
    headers: { "Content-Type": "undefined" },
    params: {
      token,
    },
  })
    .then((res) => res.data)
    .catch((err) => {
      if (!err) {
        return { status: 600 };
      }
      if (!err.response) {
        // network error
        return { status: 600 };
      } else {
        return err.response.data;
      }
    });
};

const get_visible_entities = () => {
  const token = localStorage.getItem("access_token");
  return axios({
    method: "get",
    url: `${urlOldApiEndpoint}/entities/visible`,
    params: {
      token,
      type: "ents",
    },
  })
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      return err.response;
    });
};

// https://vendor-api-development-vjuqlq6gsq-uw.a.run.app/v1/inventory/?token=&type=heatmap
const heatmap_data = () => {
  const token = localStorage.getItem("access_token");
  return axios({
    method: "get",
    // url: `${urlOldApiEndpoint}/inventory`,
    // url: "http://localhost:3000/v1/inventory/heatmap",
    url: `${apiBaseUrl}/inventory/heatmap`,
    headers: { authorization: token },

    // params: {
    //   token,
    //   type: "heatmap",
    // },
  })
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      return err.response;
    });
};

/**
 *
 *
 *
 *
 *
 * NEW
 * API methods going to new repo
 *
 *
 *
 *
 *
 *
 */

const login = async ({ email, password }) => {
  return axios({
    method: "POST",
    url: `${loginBaseUrl}/platform/auth/login`,
    data: {
      email,
      password,
    },
  });
};

const get_api_tb_vendor_inventory_tmp = () => {
  const token = localStorage.getItem("access_token");
  return axios({
    method: "GET",
    url: `${apiBaseUrl}/inventory`,
    headers: { authorization: token },
    params: {
      // token,
      // type: "inventory",
    },
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

const bands = () => {
  const token = localStorage.getItem("access_token");
  return axios({
    method: "get",
    url: `${apiBaseUrl}/analytics/bands`,
    headers: { authorization: token },
    params: {
      // token,
      // type: "bands",
    },
  })
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      return err.response;
    });
};

const get_orgs = () => {
  const token = localStorage.getItem("access_token");
  return axios({
    method: "get",
    url: `${apiBaseUrl}/organizations`,
    headers: { authorization: token },
    params: {
      // token,
      // type: "ents",
    },
  })
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      return err.response;
    });
};

const sold_thirty_days = () => {
  const token = localStorage.getItem("access_token");
  return axios({
    method: "get",
    url: `${apiBaseUrl}/analytics/sold_thirty_days`,
    headers: { authorization: token },
    params: {
      // token,
      // type: "sold_thirty_days",
    },
  })
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      return err.response;
    });
};

const sold_current_month = () => {
  const token = localStorage.getItem("access_token");
  return axios({
    method: "get",
    url: `${apiBaseUrl}/analytics/sold_current_month`,
    headers: { authorization: token },
    params: {
      // token,
      // type: "sold_current_month",
    },
  })
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      return err.response;
    });
};

const revenue_current_month = () => {
  const token = localStorage.getItem("access_token");
  return axios({
    method: "get",
    url: `${apiBaseUrl}/analytics/revenue_current_month`,
    headers: { authorization: token },
    params: {
      // token,
      // type: "revenue_current_month",
    },
  })
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      return err.response;
    });
};

const revenue_thirty_days = () => {
  const token = localStorage.getItem("access_token");
  return axios({
    method: "get",
    url: `${apiBaseUrl}/analytics/revenue_thirty_days`,
    headers: { authorization: token },
    params: {
      // token,
      // type: "revenue_thirty_days",
    },
  })
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      return err.response;
    });
};

// lenght of items with states !== Sold
const not_sold_items = () => {
  const token = localStorage.getItem("access_token");
  return axios({
    method: "get",
    url: `${apiBaseUrl}/analytics/length`,
    headers: { authorization: token },
    params: {
      // token,
      // type: "length",
    },
  })
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      return err.response;
    });
};

const equipment_count_by_type = () => {
  const token = localStorage.getItem("access_token");
  return axios({
    method: "get",
    url: `${apiBaseUrl}/analytics/count_by_type`,
    headers: { authorization: token },
    params: {
      // token,
      // type: "count_by_type",
    },
  })
    .then(({ data }) => {
      return data;
    })
    .catch((err) => {
      return err.response;
    });
};

/**
 * MISC
 * Tests
 */
const dataTest = () => {
  return axios({
    method: "get",
    url: `https://raw.githubusercontent.com/visgl/deck.gl-data/master/examples/screen-grid/uber-pickup-locations.json`,
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
};

const test_5min_timeeout = () => {
  const token = localStorage.getItem("access_token");

  return axios(``)
    .then((res) => res.data)
    .catch((err) => {
      if (!err) {
        return { status: 600 };
      }
      if (!err.response) {
        // network error
        return { status: 600 };
      } else {
        return err.response.data;
      }
    });
};

const filter_inventory = (data, page, itemsPerPage) => {
  const token = localStorage.getItem("access_token");

  return axios({
    method: "POST",
    headers: { authorization: token },
    // url: `http://localhost:9393/v1/filter`,
    url: `${apiBaseUrl}/filter`,
    params: {
      page,
      itemsPerPage,
    },
    data: {
      filters: [...data],
    },
  })
    .then((res) => res.data)
    .catch((err) => err.response.data);
};

const get_inventory_paginated = (page, itemsPerPage) => {
  const token = localStorage.getItem("access_token");

  return axios({
    method: "GET",
    headers: { authorization: token },
    url: `${apiBaseUrl}/inventory/paged`,
    params: {
      page,
      itemsPerPage,
    },
  })
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((err) => {
      console.log(err);
      console.log(err.message);
      return err;
    });
};

const forgot_password = ({ email }) => {
  return axios({
    method: "POST",
    url: `${apiBaseUrl}/public/recovery_password`,
    data: {
      email,
    },
  })
    .then((res) => res)
    .catch((err) => err.response);
};

const reset_password = ({ password, token }) => {
  return axios({
    method: "POST",
    url: `${apiBaseUrl}/public/change_password`,
    data: {
      password,
      token,
    },
  })
    .then((res) => res)
    .catch((err) => err.response);
};

const change_password_private = (data) => {
  const token = localStorage.getItem("access_token");

  return new Promise((resolve, reject) => {
    axios({
      method: "POST",
      url: `${apiBaseUrl}/user/password/change`,
      data,
      headers: { authorization: token },
    })
      .then((res) => {
        console.log(res);
        return resolve(res.data);
      })
      .catch((err) => {
        return reject(err.response.data);
      });
  });
};

const inbound_statuses = () => {
  const token = localStorage.getItem("access_token");
  console.log();
  return new Promise((resolve, reject) => {
    axios({
      method: "GET",
      headers: { authorization: token },
      url: `${apiBaseUrl}/inbound`,
    })
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
};

const get_container_statuses = () => {
  const token = localStorage.getItem("access_token");
  return new Promise((resolve, reject) => {
    axios({
      method: "get",
      url: `${apiBaseUrl}/containerstatus`,
      headers: { authorization: token },
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response.data));
  });
};

const create_container_status = (data) => {
  const token = localStorage.getItem("access_token");
  return new Promise((resolve, reject) => {
    axios({
      method: "POST",
      url: `${apiBaseUrl}/containerstatus`,
      data,
      headers: { authorization: token },
    })
      .then((res) => {
        console.log(res);
        return resolve(res.data);
      })
      .catch((err) => {
        console.log(res);
        return reject(err.response.data);
      });
  });
};

const update_toggle_container_status = (data) => {
  const token = localStorage.getItem("access_token");
  return new Promise((resolve, reject) => {
    axios({
      method: "put",

      url: `${apiBaseUrl}/containerstatus`,

      data,
      headers: { authorization: token },
      params: {
        type: "toggle",
      },
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response.data));
  });
};

const get_status_table_history = (EquipmentID) => {
  const token = localStorage.getItem("access_token");

  let workAround =
    process.env.VUE_APP_ENV == "dev" ? "development" : process.env.VUE_APP_ENV;

  return axios({
    method: "GET",
    headers: { authorization: token },
    // url: `http://localhost:1000/api/get/history/beta`,
    // url: `https://nereus-inf-qldb-nereus-${workAround}-vjuqlq6gsq-uw.a.run.app/api/get/history/beta`,
    url: `${apiBaseUrl}/status/history`,
    params: {
      EquipmentID,
      // searchField: "EquipmentID",
      // value: EquipmentID,
      // mapName: "tb_vendor_inventory",
    },
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      console.log(err.message);
      return err;
    });
};

const getInboundStatuses = () => {
  const token = localStorage.getItem("access_token");

  return axios({
    method: "GET",
    headers: { authorization: token },
    url: `${apiBaseUrl}/inbound`,
    // url: `http://localhost:9393/v1/inbound`,
  })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      console.log(err.message);
      return err;
    });
};

const send_depot_template = (data) => {
  const token = localStorage.getItem("access_token");
  return new Promise((resolve, reject) => {
    axios({
      method: "POST",
      url: `${apiBaseUrl}/notify/depots`,
      data,
      headers: { authorization: token },
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response));
  });
};

const get_depot_template = (data) => {
  const token = localStorage.getItem("access_token");
  return new Promise((resolve, reject) => {
    axios({
      method: "POST",
      url: `${apiBaseUrl}/notify/depots/preview`,
      data,
      headers: { authorization: token },
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response));
  });
};

const flag_instructions = (data) => {
  const token = localStorage.getItem("access_token");
  return new Promise((resolve, reject) => {
    axios({
      method: "POST",
      url: `${apiBaseUrl}/flag/instructions`,
      data,
      headers: { authorization: token },
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response));
  });
};

const get_inventory_qty_summs = () => {
  const token = localStorage.getItem("access_token");
  return new Promise((resolve, reject) => {
    axios({
      method: "get",
      url: `${apiBaseUrl}/inventoryaggregate`,
      headers: { authorization: token },
      params: {
        bypasscache: false,
      },
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response.data));
  });
};

const get_inventory_qty_summs_bypass_cache = () => {
  const token = localStorage.getItem("access_token");
  return new Promise((resolve, reject) => {
    axios({
      method: "get",
      url: `${apiBaseUrl}/inventoryaggregate`,
      headers: { authorization: token },
      params: {
        bypasscache: true,
      },
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response.data));
  });
};

const get_depot_terminations = () => {
  const token = localStorage.getItem("access_token");
  return new Promise((resolve, reject) => {
    axios({
      method: "get",
      url: `${apiBaseUrl}/depottermination`,
      headers: { authorization: token },
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response.data));
  });
};

const create_depot_termination = (data) => {
  const token = localStorage.getItem("access_token");
  return new Promise((resolve, reject) => {
    axios({
      method: "POST",
      url: `${apiBaseUrl}/depottermination`,
      data,
      headers: { authorization: token },
    })
      .then((res) => {
        console.log(res);
        return resolve(res.data);
      })
      .catch((err) => {
        console.log(res);
        return reject(err.response.data);
      });
  });
};

const update_toggle_depot_termination = (data) => {
  const token = localStorage.getItem("access_token");
  return new Promise((resolve, reject) => {
    axios({
      method: "put",

      url: `${apiBaseUrl}/depottermination`,

      data,
      headers: { authorization: token },
      params: {
        type: "toggle",
      },
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response.data));
  });
};

const get_translation_maps = () => {
  const token = localStorage.getItem("access_token");
  return new Promise((resolve, reject) => {
    axios({
      method: "GET",
      url: `${apiBaseUrl}/translation/maps`,
      headers: { authorization: token },
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response.data));
  });
};

const create_flag_instructions = (data) => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("access_token");
    axios({
      method: "POST",
      url: `${apiBaseUrl}/flag/instructions`,
      data,
      headers: { authorization: token },
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response));
  });
};

const get_flags_instructions = () => {
  const token = localStorage.getItem("access_token");
  return new Promise((resolve, reject) => {
    axios({
      method: "GET",
      url: `${apiBaseUrl}/flag/instructions`,
      headers: { authorization: token },
    })
      .then((res) => {
        // console.log("get_flags_instructions data", res.data);
        return resolve(res.data);
      })
      .catch((err) => reject(err));
  });
};

const get_inventory_allocations = () => {
  const token = localStorage.getItem("access_token");
  return new Promise((resolve, reject) => {
    axios({
      method: "get",
      url: `${apiBaseUrl}/inventoryallocation`,
      headers: { authorization: token },
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response.data));
  });
};

const create_inventory_allocation = (data) => {
  const token = localStorage.getItem("access_token");
  return new Promise((resolve, reject) => {
    axios({
      method: "POST",
      url: `${apiBaseUrl}/inventoryallocation`,
      data,
      headers: { authorization: token },
    })
      .then((res) => {
        console.log(res);
        return resolve(res.data);
      })
      .catch((err) => {
        console.log(res);
        return reject(err.response.data);
      });
  });
};

const update_toggle_inventory_allocation = (data) => {
  const token = localStorage.getItem("access_token");
  return new Promise((resolve, reject) => {
    axios({
      method: "put",

      url: `${apiBaseUrl}/inventoryallocation`,

      data,
      headers: { authorization: token },
      params: {
        type: "toggle",
      },
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response.data));
  });
};

const get_countries = () => {
  const token = localStorage.getItem("access_token");
  return new Promise((resolve, reject) => {
    axios({
      method: "get",
      url: `${apiBaseUrl}/countrylookp`,
      headers: { authorization: token },
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response.data));
  });
};

const get_depot_details = (data) => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("access_token");
    axios({
      method: "GET",
      url: `${apiBaseUrl}/depots/single`,
      headers: { authorization: token },
      params: {
        code: data,
      },
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response));
  });
};

const get_depots_all = async (data) => {
  const token = localStorage.getItem("access_token");
  const res = await axios({
    method: "GET",
    url: `${apiBaseUrl}/depots`,
    headers: { authorization: token },
  });
  return res.data;
  // .catch((err) => reject(err.response));
  // });
};

const get_organizations = async () => {
  const token = localStorage.getItem("access_token");

  return axios({
    method: "GET",
    url: `${apiBaseUrl}/vendororganization`,
    headers: { authorization: token },
  });
};
const get_organizations_vendor = async () => {
  const token = localStorage.getItem("access_token");

  return axios({
    method: "GET",
    url: `${apiBaseUrl}/vendororganization/vendor`,
    headers: { authorization: token },
  });
};

const create_new_org = (data) =>
  new Promise((resolve, reject) => {
    const token = localStorage.getItem("access_token");
    axios({
      method: "POST",
      url: `${apiBaseUrl}/vendororganization`,
      data,
      headers: { authorization: token },
    })
      .then((res) => {
        return resolve(res.data);
      })
      .catch((err) => reject(err.response));
  });

const update_new_org = (data) =>
  new Promise((resolve, reject) => {
    const token = localStorage.getItem("access_token");
    axios({
      method: "PUT",
      url: `${apiBaseUrl}/vendororganization`,
      data,
      headers: { authorization: token },
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response));
  });

const get_contacts = (organization_id) =>
  new Promise((resolve, reject) => {
    const token = localStorage.getItem("access_token");
    axios({
      method: "GET",
      url: `${apiBaseUrl}/vendororgcontact`,
      headers: { authorization: token },
      params: {
        organization_id,
      },
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response));
  });

const get_contacts_salereps = (organization_id) =>
  new Promise((resolve, reject) => {
    const token = localStorage.getItem("access_token");
    axios({
      method: "GET",
      url: `${apiBaseUrl}/vendororgcontact/salereps`,
      headers: { authorization: token },
      params: {
        organization_id,
      },
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response));
  });

const save_contact = ({ organization_id, data }) =>
  new Promise((resolve, reject) => {
    const token = localStorage.getItem("access_token");
    axios({
      method: "POST",
      url: `${apiBaseUrl}/vendororgcontact`,
      headers: { authorization: token },
      params: {
        organization_id,
      },
      data,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response));
  });

const update_contact = ({ organization_id, data }) =>
  new Promise((resolve, reject) => {
    const token = localStorage.getItem("access_token");
    axios({
      method: "PUT",
      url: `${apiBaseUrl}/vendororgcontact`,
      headers: { authorization: token },
      params: {
        organization_id,
      },
      data,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response));
  });

const delete_contact = ({ contact_id }) =>
  new Promise((resolve, reject) => {
    const token = localStorage.getItem("access_token");
    axios({
      method: "DELETE",
      url: `${apiBaseUrl}/vendororgcontact`,
      headers: { authorization: token },
      data: { contact_id },
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response));
  });
const get_all_orders = async () => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("access_token");

    axios({
      method: "GET",
      url: `${apiBaseUrl}/orders/all`,
      headers: { authorization: token },
    })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};
const get_all_orders_details_with_eoo_release = async () => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("access_token");

    axios({
      method: "GET",
      url: `${apiBaseUrl}/orders/all/detailsWithEOORelease`,
      headers: { authorization: token },
    })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

const create_new_order = async (data) =>
  new Promise((resolve, reject) => {
    const token = localStorage.getItem("access_token");
    axios({
      method: "POST",
      url: `${apiBaseUrl}/orders`,
      headers: { authorization: token },

      data,
    })
      .then((res) => resolve(res.data))
      .catch((err) => reject(err.response));
  });

const get_all_orders_details = async () => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("access_token");

    axios({
      method: "GET",
      url: `${apiBaseUrl}/orders/all/details`,
      headers: { authorization: token },
    })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

const get_all_orders_pickups = async () => {
  const token = localStorage.getItem("access_token");

  return axios({
    method: "GET",
    url: `${apiBaseUrl}/orders/all/pickups`,
    headers: { authorization: token },
  });
};
const get_single_order_details_by_docNo = async (docNo) => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("access_token");

    axios({
      method: "GET",
      url: `${apiBaseUrl}/orders/single/details?docno=${docNo}`,
      headers: { authorization: token },
    })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

const get_single_order_details_by_docNo_docType_inv = async (docNo) => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("access_token");

    axios({
      method: "GET",
      url: `${apiBaseUrl}/orders/single/details/min?docno=${docNo}`,
      headers: { authorization: token },
    })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

const get_single_order_headers_by_docNo = async (docNo) => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("access_token");

    axios({
      method: "GET",
      url: `${apiBaseUrl}/orders/single?docno=${docNo}`,
      headers: { authorization: token },
    })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

const get_single_order_headers_by_docNo_docType_inv = async (docNo) => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem("access_token");

    axios({
      method: "GET",
      url: `${apiBaseUrl}/orders/single/min?docno=${docNo}`,
      headers: { authorization: token },
    })
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

const get_customer_depot = async (data) => {
  const token = localStorage.getItem("access_token");
  let { id } = data;

  try {
    const res = await axios({
      method: "GET",
      // url: `${apiBaseUrl}/visibility/depotgroup/customers`,
      url: `${apiBaseUrl}/depotcustomervisible/single`,
      headers: { authorization: token },
      params: { vids_id: id },
    });

    // console.log("get_customer_depot res.data.data", res.data.data);
    if (data.pass) return res.data.data;
    else throw error;
  } catch (error) {
    throw error;
  }
};

const save_customer_depot = async (data) => {
  const token = localStorage.getItem("access_token");
  let { customerOrganizationId, vids_id } = data;

  try {
    const res = await axios({
      method: "POST",
      url: `${apiBaseUrl}/depotcustomervisible`,
      headers: { authorization: token },
      data: {
        toEntity: customerOrganizationId,
        vendor_inventory_depot_summ_id: vids_id,
      },
    });

    return res.data.data;
  } catch (error) {
    throw error;
  }
};

const delete_depot = async (data) => {
  const token = localStorage.getItem("access_token");
  try {
    const res = await axios({
      method: "DELETE",
      url: `${apiBaseUrl}/depotcustomervisible`,
      headers: { authorization: token },
      data,
    });

    return res.data.data;
  } catch (error) {
    throw error;
  }
};

const save_contact_depot = async (data) => {
  const token = localStorage.getItem("access_token");

  try {
    const res = await axios({
      method: "POST",
      url: `${apiBaseUrl}/vendor/depot/contacts`,
      headers: { authorization: token },
      data: data,
    });

    console.log(res);

    return res.data.data;
  } catch (error) {
    throw error;
  }
};

const update_contact_depot = async (data) => {
  const token = localStorage.getItem("access_token");

  try {
    const res = await axios({
      method: "PUT",
      url: `${apiBaseUrl}/vendor/depot/contacts`,
      headers: { authorization: token },
      data: data,
    });

    console.log(res);

    return res.data.data;
  } catch (error) {
    throw error;
  }
};

const get_contact_depot = async (id) => {
  const token = localStorage.getItem("access_token");

  try {
    const res = await axios({
      method: "GET",
      url: `${apiBaseUrl}/vendor/depot/contacts`,
      headers: { authorization: token },
      params: { depot_id: id },
    });

    console.log(res);
    return res.data.data;
  } catch (error) {
    throw error;
  }
};

const delete_contact_depot = async (data) => {
  const token = localStorage.getItem("access_token");
  try {
    const res = await axios({
      method: "DELETE",
      url: `${apiBaseUrl}/vendor/depot/contacts`,
      headers: { authorization: token },
      data,
    });

    return res.data.data;
  } catch (error) {
    throw error;
  }
};

const save_dates_status_depot = async (data) => {
  const token = localStorage.getItem("access_token");
  data.date_to =
    data.date_to && data.date_to.length > 0 ? data.date_to : undefined;
  try {
    const res = await axios({
      method: "POST",
      url: `${apiBaseUrl}/depots/status`,
      headers: { authorization: token },
      data: data,
    });

    console.log(res);

    return res.data.data;
  } catch (error) {
    throw error;
  }
};
const update_dates_status_depot = async (data) => {
  const token = localStorage.getItem("access_token");
  data.date_to =
    data.date_to && data.date_to.length > 0 ? data.date_to : undefined;

  try {
    const res = await axios({
      method: "PUT",
      url: `${apiBaseUrl}/depots/status`,
      headers: { authorization: token },
      data: data,
    });

    return res.data.data;
  } catch (error) {
    throw error;
  }
};
const change_sent_value_single_order_details = async (data) => {
  const token = localStorage.getItem("access_token");

  try {
    const res = await axios({
      method: "PUT",
      url: `${apiBaseUrl}/orders/single/details/sent`,
      headers: { authorization: token },
      data: data,
    });

    return res.data.data;
  } catch (error) {
    throw error;
  }
};

const get_coname_inventoryqtysumm = async (id) => {
  const token = localStorage.getItem("access_token");

  try {
    const res = await axios({
      method: "GET",
      url: `${apiBaseUrl}/inventoryaggregate/coname`,
      headers: { authorization: token },
    });

    return res.data.data.coName;
  } catch (error) {
    throw error;
  }
};

const delete_flag_instructions = async (data) => {
  const token = localStorage.getItem("access_token");
  try {
    const res = await axios({
      method: "DELETE",
      url: `${apiBaseUrl}/flag/instructions`,
      headers: { authorization: token },
      data,
    });

    return res.data;
  } catch (error) {
    throw error;
  }
};
const delete_container_comment = async (data) => {
  const token = localStorage.getItem("access_token");

  try {
    const res = await axios({
      method: "DELETE",
      url: `${apiBaseUrl}/inventory/comment`,
      headers: { authorization: token },
      data: data,
    });

    return res.data.data;
  } catch (error) {
    throw error;
  }
};

const update_container_comment = async (data) => {
  const token = localStorage.getItem("access_token");

  try {
    const res = await axios({
      method: "POST",
      url: `${apiBaseUrl}/inventory/comment`,
      headers: { authorization: token },
      data: data,
    });
    console.log("update comment response", res.data);

    return res.data.data;
  } catch (error) {
    throw error;
  }
};
const get_container_comment = async (data) => {
  const token = localStorage.getItem("access_token");
  try {
    const body = {
      method: "GET",
      url: `${apiBaseUrl}/inventory/comment?equipmenid=${data.EquipmentID}`,
      headers: { authorization: token },
    };
    const res = await axios(body);

    return res.data.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
const update_mr_comment_bos_display = async (data) => {
  const token = localStorage.getItem("access_token");
  try {
    const body = {
      method: "PUT",
      url: `${apiBaseUrl}/orders/single/mrcomments`,
      headers: { authorization: token },
      data,
    };
    const res = await axios(body);
    console.log("res", res);

    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const save_tbd_containers = async ({ data }) => {
  const token = localStorage.getItem("access_token");

  return axios({
    method: "POST",
    url: `${apiBaseUrl}/inventory/tbd`,
    headers: { authorization: token },

    data,
  });
};

const send_invite_emails = async (data) => {
  const token = localStorage.getItem("access_token");

  return axios({
    method: "POST",
    url: `${apiBaseUrl}/invite/email`,
    headers: { authorization: token },
    data,
  });
};
const get_good_reefers = async (data) => {
  const token = localStorage.getItem("access_token");

  return axios({
    method: "GET",
    url: `${apiBaseUrl}/inventoryaggregate/reefersgood`,
    headers: { authorization: token },
    data,
  });
};
const get_bad_reefers = async (data) => {
  const token = localStorage.getItem("access_token");

  return axios({
    method: "GET",
    url: `${apiBaseUrl}/inventoryaggregate/reefersbad`,
    headers: { authorization: token },
    data,
  });
};
const refreshDepots = async () => {
  return axios({
    method: "GET",
    url: `${apiBaseUrl}/public/depot/updatenotdefined`,
  });
};

const get_unique_link_invitation = () => {
  const token = localStorage.getItem("access_token");

  return axios({
    method: "Get",
    url: `${process.env.VUE_APP_BASE_URL_ADMIN}/admin/organizations/token/create`,
    headers: { authorization: `Bearer ${token}` },
  });
};

const sent_serve_bos_email = async (data) => {
  const token = localStorage.getItem("access_token");

  return axios({
    method: "POST",
    url: `${process.env.VUE_APP_FILES_URL_API}/orders/bos/serveremail`,
    headers: { authorization: token },
    data,
  });
};

const gatebuy = async () => {
  return axios({
    method: "GET",
    url: `${apiBaseUrl}/inventory/gatebuy`,
  });
};
export {
  refreshDepots,
  login,
  get_api_tb_vendor_inventory_tmp,
  create_api_tb_vendor_inventory,
  update_api_tb_vendor_inventory,
  create_vendor_inventory_update_log,
  upload_inventory_file_new_system,
  upload_inventory_file,
  validate_inventory_file,
  convert_inventory_file,
  upload_inventory_file_step2,
  bands, //they have status !== sold and have date Available
  dataTest,
  get_orgs,
  heatmap_data,
  sold_thirty_days,
  sold_current_month,
  revenue_current_month,
  revenue_thirty_days,
  not_sold_items,
  equipment_count_by_type,
  get_visible_entities,
  create_buyer_party,
  update_buyer_party,
  update_toggle_buyer_party,
  get_saved_filters,
  create_filter,
  update_saved_filter,
  delete_filter,
  test_5min_timeeout,
  get_header,
  create_header,
  update_header,
  delete_header,
  filter_inventory,
  get_inventory_paginated,
  forgot_password,
  reset_password,
  inbound_statuses,
  get_container_statuses,
  create_container_status,
  update_toggle_container_status,
  get_status_table_history,
  getInboundStatuses,
  send_depot_template,
  get_depot_template,
  flag_instructions,
  get_inventory_qty_summs,
  get_depot_terminations,
  create_depot_termination,
  update_toggle_depot_termination,
  get_translation_maps,
  create_flag_instructions,
  get_flags_instructions,
  get_inventory_allocations,
  create_inventory_allocation,
  update_toggle_inventory_allocation,
  get_countries,
  change_password_private,
  get_depot_details,
  get_organizations,
  create_new_org,
  update_new_org,
  get_contacts,
  save_contact,
  update_contact,
  delete_contact,
  get_all_orders,
  get_all_orders_details,
  get_depots_all,
  apiBaseUrl,
  apiGatewayUrlNonLocal,
  corsAny,
  urlOldApiEndpoint,
  urlScriptCaseDirect,
  create_new_order,
  get_customer_depot,
  save_customer_depot,
  delete_depot,
  get_single_order_details_by_docNo,
  get_single_order_headers_by_docNo,
  get_all_orders_details_with_eoo_release,
  save_contact_depot,
  get_contact_depot,
  update_contact_depot,
  delete_contact_depot,
  save_dates_status_depot,
  update_dates_status_depot,
  change_sent_value_single_order_details,
  get_inventory_qty_summs_bypass_cache,
  get_single_order_details_by_docNo_docType_inv,
  get_single_order_headers_by_docNo_docType_inv,
  get_contacts_salereps,
  delete_container_comment,
  update_container_comment,
  get_container_comment,
  get_coname_inventoryqtysumm,
  delete_flag_instructions,
  update_mr_comment_bos_display,
  save_tbd_containers,
  send_invite_emails,
  get_good_reefers,
  get_bad_reefers,
  get_unique_link_invitation,
  sent_serve_bos_email,
  get_all_orders_pickups,
  get_organizations_vendor,
  gatebuy,
};
